<template>
  <div class="con-info">
      <div class="ns-info">
      <SyaratAda/>
      </div>
  </div>
</template>
<script>
import SyaratAda from '@/components/Syarat.vue'
export default {
  components: {
    // Popup,
    SyaratAda,
  },
  data() {
    return {
      
    };
  },
  computed: {
    
  },
  mounted() {
    
  },
};
</script>
<style>
</style>
<style scoped>
.con-info{
  background-image: url("../assets/backdrop.png");
  background-color: #1e0030;
  background-repeat: no-repeat;
  background-size:cover;
  background-position: bottom;
  padding: 20px;
  margin: 0px;
  height: 100vh;
}
.ns-info{
  margin-top: 1.5rem;
  color: #fff !important;
  background: #410e43b3;
  box-shadow: inset #d100b6 0px 0px 6px 4px;
  background-repeat: repeat;
  border-radius: 25px;
  overflow: scroll;
  height: 87vh;
  padding: 30px;
}
</style>
